/* ---Global styles--- */

$base-color: #f3f4f5;
$light-accent: #fff;
$tertiary: #e86ce0;
$secondary: #5b8dd7;
$dark-accent: #2b0719;
$emphasis: #2cd2ee;
$hover-color: $tertiary;
$neutral: #757575;
$highlight: $tertiary;
$size: 174px;
$transition-rate: 1s;

:export {
  highlight: $highlight;
  tertiary: $tertiary;
}

body {
  font-family: 'Rubik', sans-serif;
  font-size: 1em;
  margin: 0;
}

h1 {
  color: $light-accent;
  text-shadow: 0 0 10px $tertiary, 0 0 7px $tertiary;
  font-size: 2.1em;
  margin: 10px 0;
}

h2 {
  color: $neutral;
  margin: 9.2px 0;
  font-size: 1.4em;
}

button {
  outline: none;
  border: none;
  font-family: inherit;
  cursor: pointer;
  font-size: 1em;
}

button,
svg {
  transition: 0.1s;
}

button:disabled,
input[type=color]:disabled {
  cursor: auto;
}

.app {
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  transition: background-color $transition-rate;
}
