/* ---Selection box--- */

.album-selection {
  flex: 1 1;

  p {
    color: $neutral;
    font-weight: bold;
    margin: 8px 0;
    font-size: 0.9em;
  }
}

.selection-box {
  height: 105px;
  padding: 7.5px 7.5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  overflow: auto;
  background: white;
  border: none;
  border: 2px dashed lightgray;

  > div {
    background: white;
    height: 20px;
    margin: 2.5px;
    box-shadow: 0 0 5px $tertiary, 0 0 5px $tertiary;
    display: flex;
    cursor: grab;
    position: relative;
    width: 48.5%;
  }

  img {
    width: 20px;
  }

  span {
    position: absolute;
    padding: 2px 3px 2px 5px;
    font-size: 0.875em;
    font-weight: bold;
    color: $neutral;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    user-select: none;
  }

  button {
    color: $secondary;
    background: inherit;
    width: 15px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 0.9em;
  }

  button:hover,
  button:focus {
    color: $emphasis;
  }
}

.title-container {
  width: 67%;
  position: relative;
  overflow: hidden;
}

/* Webkit scrollbar not working with nesting... */
.selection-box::-webkit-scrollbar {
  height: 9px;
}

.selection-box::-webkit-scrollbar-track {
  background: $light-accent;
}

.selection-box::-webkit-scrollbar-thumb {
  background: darkgray;
}

.selection-box::-webkit-scrollbar-thumb:hover {
  background: gray;
}
