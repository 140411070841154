@media only screen and (min-width: 325px) {
  .album-options {
    label {
      min-width: 58px;
    }
  }
}

@media only screen and (min-width: 420px) {
  .collage-btn {
    margin: 0 9.3px;
  }

  .edit-dock {
    width: auto;
  }
}

@media only screen and (min-width: 750px) {
  .album-options span {
    width: 50px;
  }

  .warning {
    margin: 2px 0 0 60px;
  }
}

@mixin smallSelectionBoxHeight($windowHeight) {
  @media only screen and (min-height: $windowHeight) {
    .selection-box {
      height: $windowHeight - 445px;
    }
  }
}

@for $i from 23 through 25 {
  @include smallSelectionBoxHeight(25px * $i);
}

@media only screen and (min-height: 650px) {
  .menu h2 {
    margin: 15px 0;
  }
}

@mixin largeSelectionBoxHeight($windowHeight) {
  @media only screen and (min-height: $windowHeight) {
    .selection-box {
      height: $windowHeight - 470px;
    }
  }
}

@for $i from 26 through 65 {
  @include largeSelectionBoxHeight(25px * $i);
}
