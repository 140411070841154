/* ---Collage and editing dock--- */

.collage {
  width: 100%;
  min-height: 520px;
  position: relative;
  margin: 0 15px 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform $transition-rate;
}

.edit-dock {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  background: $base-color;
  padding: 15px 10px;
  border-radius: 0 0 5px 5px;
  border: 2.5px solid $emphasis;
  border-top: none;
  box-shadow: 1px 1px 5px $neutral;
  transition: $transition-rate;

  a {
    display: none;
  }
}

.collage-btn {
  position: relative;
  box-shadow: 1px 1px 5px $neutral;
  width: 42px;
  height: 38px;
  background: $emphasis;
  color: $light-accent;
  border-radius: 10px;
  font-size: 1.3em;
  transition: opacity $transition-rate;
  display: flex;
  justify-content: center;
  align-items: center;
}

.collage-btn:hover,
.collage-btn:focus {
  background: $tertiary;
}

.collage-btn:focus {
  box-shadow: 0 0 7px $highlight;
}

.collage-btn:disabled:hover {
  background: $emphasis;
  border-color: $emphasis;
}

.collage-btn input[type=color] {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  opacity: 0;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  border-radius: inherit;
  cursor: pointer;
}

@mixin halfTriangle($vertical, $horizontal, $triangle-size) {
  .#{$vertical}-#{$horizontal} {
    #{$vertical}: 0;
    #{$horizontal}: 0;
    border-color: transparent;
    border-#{$vertical}-color: white;
    border-width: 0 $triangle-size;
    border-#{$vertical}-width: $triangle-size;
    border-#{$horizontal}-width: 0;
  }
}

.collage-grid {
  display: grid;
  position: absolute;
  transition: opacity $transition-rate;

  > div {
    height: $size;
    width: $size;
    position: relative;
  }

  img {
    position: absolute;
    z-index: 0;
    width: $size;
    height: $size;
  }

  .overlay {
    position: absolute;
    height: 0;
    width: 0;
    z-index: 1;
    border-style: solid;
    transform: scale(1.01);
    transition: border-color $transition-rate;
  }

  .top {
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-color: transparent;
    border-top-color: white;
    transform-origin: 50% 100%;
  }

  .right {
    right: 0;
    border-color: transparent;
    border-right-color: white;
    transform-origin: 0% 50%;
  }

  .bottom {
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-color: transparent;
    border-bottom-color: white;
    transform-origin: 50% 0%;
  }

  .left {
    left: 0;
    border-color: transparent;
    border-left-color: white;
    transform-origin: 100% 50%;
  }

  .right,
  .left {
    top: 50%;
    transform: translateY(-50%) scale(1.01);
  }
}

.blank-square {
  background: transparent;
}
