/* ---Album selection--- */

.album-options {
  form {
    margin-bottom: 9px;
  }

  form div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    position: relative;
  }

  label {
    flex: 1;
    display: flex;
    align-items: center;
  }

  span {
    display: inline-block;
    width: 70px;
    color: $secondary;
    font-size: 1.05em;
  }

  input {
    margin-left: 10px;
    outline: none;
    border: none;
    border-bottom: 2.5px solid $emphasis;
    box-sizing: border-box;
    padding: 8px 30px 8px 8px;
    font-family: inherit;
    font-size: 1em;
  }

  input,
  input::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  > button {
    align-self: flex-end;
    background: $secondary;
    border-radius: 6px;
    padding: 5.5px 6px;
  }

  form button {
    position: absolute;
    right: 0;
    height: 100%;
    background: transparent;
    color: $secondary;
    border: none;
    border-radius: 0 8px 8px 0;
  }

  input:hover {
    border-color: $secondary;
  }

  input:focus {
    border-color: $tertiary;
  }

  input:disabled {
    background: transparent;
    border-color: darkgray;
  }

  form button:hover,
  form button:focus {
    background: transparent;
    box-shadow: none;
    color: $tertiary;
  }

  form button:disabled {
    color: darkgray;
    cursor: auto;
  }
}

.artist-search-box {
  outline: none;
}
