/* ---Shape selection--- */

.shapes {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 46px;
}

.shape-btn {
  position: relative;
  background: rgba(0, 0, 0, 0);
  font-size: 2em;
  padding: 0;

  svg {
    fill: transparent;
    stroke: $emphasis;
    stroke-width: 3.5;
    transform-origin: 50% 25%;
    transform: scale(1.5);
  }

  > * {
    pointer-events: none;
  }
}

.shape-btn:hover svg,
.shape-btn:focus svg {
  stroke: $tertiary;
}

.heart svg polygon {
  transform: scale(1.1) translate(-1.5px, -1px);
}

/* ---Collage submission--- */

.collage-submit {
  display: flex;
  width: 100%;
  align-items: flex-end;

  button {
    background: linear-gradient(to bottom, $emphasis, $secondary);
    border-radius: 10px;
    padding: 8px;
    font-size: 1.05em;
    font-weight: bold;
    height: 40px;
    margin-top: 15px;
    transition: none;
  }

  .warning {
    position: relative;
    width: 100%;
    text-align: right;
    margin: 0;
    display: block;
    min-height: 20px;
  }
}
